import React from 'react';
import fire from '../../config/shit';
import SPAHeader from '../../components/spacomponents/SPAHeader';

import FundToolsSpa from '../../components/spacomponents/FundSpa';
import SitToolsSpa from '../../components/spacomponents/SitSpa';
import './herramientas.css';


class herramientas extends React.Component {
    constructor(props) {
        super(props)
        this.state = ({
            user: null,
        });
        this.authListener = this.authListener.bind(this);
        }

        componentDidMount() {
            this.authListener()
        }
    
        authListener() {
            fire.auth().onAuthStateChanged((user) => {
                console.log(user);
                if (user) {
                    this.setState({ user });
                    localStorage.setItem('user',user.uid);
                } else {
                    this.setState({ user: null });
                    localStorage.removeItem('user');
                }
            });
        }

    render () {

        if (this.state.user != null ) {

            return (
                <div>
                <SPAHeader auth="true" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                                <h1 id="ToolTitles1">Herramientas Fundamentales</h1>
                                <FundToolsSpa />
                                
                                <h1 id="ToolTitles2">Herramientas Situacionales</h1>
                                <SitToolsSpa />
                            </div>
                        </div>
                    </div>
                </div>
            )

        } else if (this.state.user == null) {

            return (
                <div>
                <SPAHeader auth="true" />
                    <div className="Hero">
                        <div className="HeroGroup">
                            <div>
                            <h1>Ingresa Para Visualizar Este Contenido</h1>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }
}

export default herramientas;